<template>
  <section class="home-cta">
    <div class="home-cta-ctn">
      <h3 class="headline-3 fbold">Parlons de votre prochain recrutement</h3>
      <div class="body-lg">
        Atteignez une audience qualifiée et recrutez efficacement grâce à Onlytechs.
      </div>
      <div class="home-cta-btns">
        <CtaButton ot label="Contactez-nous" @click="onClickContactUs"/>
        <CtaButton ot label="Recruter un tech" white @click="onClickHelpMeRecruit"/>
      </div>
    </div>
  </section>
</template>

<script>
import CtaButton from 'front-lib-os/src/components/atoms/CtaButton.vue'

export default {
  name: 'HomeCta',
  components: {
    CtaButton
  },
  methods: {
    onClickHelpMeRecruit() {
      window.open('https://calendly.com/onlytechs/30min')
    },
    onClickContactUs() {
      document.getElementById("contact").scrollIntoView()
    }
  }
}
</script>

<style lang="scss">
.home-cta {
  background: #FFFFFD;
  padding: 96px 112px;
  
  .home-cta-ctn {
    width: $desktopContainer;
    background: $primaryOt800;
    padding: 64px;
    border-radius: 16px;
    margin: 0 auto;
    text-align: center;

    .headline-3 {
      color: $neutral25;
      margin: 0 0 20px;
    }

    .body-lg {
      color: $neutral25;
      margin: 0 0 32px;
    }

    .cstm-button {
      &:first-child {
        margin-right: 16px;

        button {
          border-color: $neutral25;
          background: $primaryOt800;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-cta {
    padding: 64px 24px;

    .home-cta-ctn {
      width: 100%;
      padding: 32px 24px;

      .headline-3 {
        font-size: 24px;
        line-height: 32px;
      }

      .cstm-button {
        &:first-child {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
}

</style>