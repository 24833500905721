<template>
  <section class="home-faq">
    <div class="home-faq-cnt">
      <div class="faq-left">
        <h2 class="headline-2 fbold">FAQs</h2>
        <div class="body-md">
          Tout ce que vous devez savoir sur le produit et la facturation. Vous
          ne trouvez pas la réponse que vous cherchez ? N'hésitez pas à
          contacter notre équipe.
        </div>
        <CtaButton label="Contactez nous" white @click="onClickContactUs" />
      </div>

      <div class="faq-right">
        <FaqItem
          v-for="(item, index) in faq"
          :key="index.title"
          :title="item.title"
          :description="item.description"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CtaButton from "front-lib-os/src/components/atoms/CtaButton.vue";
import FaqItem from "../FaqItem.vue";

export default {
  name: "HomeFaq",
  components: {
    CtaButton,
    FaqItem,
  },
  data() {
    return {
      faq: [
        {
          title: `Quels types de profils techniques pouvez-vous nous aider à recruter ?`,
          description: `Onlytechs est spécialisé dans le recrutement de tous les types de profils techniques, qu'il s'agisse de développeurs front-end, back-end, mobile, full-stack, DevOps, ou même de projects owner ou responsables applicatifs, et bien plus encore. Notre base de données comprend une variété de compétences et d'expériences pour répondre à vos besoins spécifiques.`,
        },
        {
          title: `Qu'est-ce qui distingue Onlytechs des autres solutions de recrutement ?`,
          description: `Notre différenciation réside dans notre expertise en recrutement technique et notre modèle de rémunération au succès. Nous ne facturons que lorsque vous recrutez un candidat, ce qui signifie que nous sommes pleinement engagés à vous fournir des candidats de qualité qui correspondent à vos attentes.`,
        },
        {
          title: `Comment fonctionne le modèle de rémunération au succès ?`,
          description: `Avec notre modèle de rémunération au succès, vous ne payez que lorsque vous embauchez un candidat que nous avons présenté. Il n'y a pas de frais initiaux. Vous pouvez être sûr que vous ne dépenserez que lorsque vous obtiendrez des résultats concrets.`,
        },
        {
          title: `Combien de temps faut-il pour trouver des candidats qualifiés avec Onlytechs ?`,
          description: `Notre objectif est de vous présenter une liste de candidats qualifiés dans un délai de 15 jours à compter de notre appel de brief initial. Ce délai peut varier en fonction de la complexité du poste et de vos besoins spécifiques.`,
        },
        {
          title: `Pouvons-nous collaborer étroitement avec votre équipe de chasseurs de tête ?`,
          description: `Absolument ! Nous encourageons la collaboration étroite avec nos chasseurs de tête. Vous serez en contact régulier avec eux tout au long du processus de recrutement pour vous assurer que vos critères et vos attentes sont parfaitement compris et respectés.`,
        },
        {
          title: `Y a-t-il une garantie de satisfaction avec Onlytechs ?`,
          description: `Oui, nous offrons une garantie de satisfaction. Si le candidat recruté ne correspond pas à vos attentes dans un certain délai, nous nous engageons à vous présenter des solutions alternatives jusqu'à votre entière satisfaction.`,
        },
        {
          title: `Quels sont les coûts associés à Onlytechs ?`,
          description: `Les coûts varient en fonction de votre choix de package et du nombre de candidats recrutés. Vous trouverez des informations détaillées sur nos tarifs sur notre page de tarification. N'oubliez pas que notre modèle de rémunération est basé sur le succès, ce qui signifie zéro frais initiaux.`,
        },
      ],
    };
  },
  methods: {
    onClickContactUs() {
      document.getElementById("contact").scrollIntoView();
    },
  },
};
</script>

<style lang="scss">
.home-faq {
  background: $white;
  padding: 96px 112px;
  text-align: left;

  .home-faq-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .faq-left {
      width: 488px;
      float: left;

      .headline-2 {
        color: $neutral900;
        margin: 0;
      }

      .body-md {
        color: $neutral600;
        margin: 16px 0 40px;
      }
    }

    .faq-right {
      width: 696px;
      float: right;
    }
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-faq {
    padding: 0 24px;

    .home-faq-cnt {
      width: 100%;

      .faq-left {
        width: 100%;
        float: none;

        .cstm-button {
          margin-bottom: 32px;
        }
      }

      .faq-right {
        width: 100%;
        float: none;
      }
    }
  }
}
</style>