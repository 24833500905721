<template>
  <section class="home-testimony">
    <div class="home-testimony-cnt">
      <Carousel 
        :per-page="1"
        pagination-color="#667085"
        pagination-active-color="#FCFCFD"
        autoplay
        loop
        :autoplayTimeout="4000"
      >
        <Slide v-for="t of testimonies" :key="t.name">
          <div class="home-testimony-item">
            <div class="headline-3">{{ t.title }}</div>
            <img class="avatar" src="https://place-hold.it/64x64" alt="avatar" />
            <div class="name body-lg fsemi">{{ t.name }}</div>
            <div class="body-md">{{ t.company }}</div>
          </div>
        </Slide>
      </Carousel>
    </div>
  </section>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel'
export default {
  name: 'HomeTestimony',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      testimonies: [
        {
          title: "Lorem ipsum toussa toussa - 1",
          avatar: "",
          name: "John Doe - 1",
          company: "Company - 1",
        },
        {
          title: "Lorem ipsum toussa toussa - 2",
          avatar: "",
          name: "John Doe - 2",
          company: "Company - 2",
        },
        {
          title: "Lorem ipsum toussa toussa - 3",
          avatar: "",
          name: "John Doe - 3",
          company: "Company - 3",
        },
      ],
    }
  }
}
</script>

<style lang="scss">
.home-testimony {
  background: $neutral900;
  color: $neutral25;
  padding: 96px 0;
  text-align: center;

  .home-testimony-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .home-testimony-item {
      width: $desktopContainer;

      .avatar {
        margin: 32px 0 16px;
        border-radius: 50%;
        width: 64px;
        height: 64px;
      }
    
      .name {
        margin: 0 0 4px;
      }
    }

    .VueCarousel-dot:focus {
      outline: none!important;
    }
  }
}
</style>