<template>
  <section class="home-hero">
    <h1 class="headline-1 fbold">Boostez votre recrutement avec Onlytechs.</h1>
    <div class="body-lg">
     Trouvez vos experts techniques grâce à notre équipe de chasseurs de tête experts.
    </div>
    <div class="cta">
      <CtaButton
        label="Aidez-moi à recruter"
        white
        @click="onClickHelpMeRecruit"
      />
    </div>
  </section>
</template>

<script>
import CtaButton from "front-lib-os/src/components/atoms/CtaButton.vue";

export default {
  name: "HomeHero",
  components: {
    CtaButton,
  },
  methods: {
    onClickHelpMeRecruit() {
      window.open(
        "https://calendly.com/onlytechs/30min"
      );
    },
  },
};
</script>

<style lang="scss">
.home-hero {
  color: $neutral25;
  background: linear-gradient(45deg, #42307D 0%, #7F56D9 100%);
  padding: 96px 112px;
  text-align: center;

  .headline-1 {
    margin: 12px auto 24px;
    max-width: 960px;
    color: $neutral25;
  }

  .body-lg {
    margin: 0 auto 40px;
    max-width: 768px;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-hero {
    padding: 64px 24px;
    text-align: left;

    .headline-1 {
      font-size: 40px;
      line-height: 48px; /* 120% */
    }
  }
}
</style>