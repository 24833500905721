<template>
  <div class="home">
    <HomeHero/>
    <HomeInfo/>
    <HomePerks/>
    <HomeSteps/>
    <HomePricing/>
    <HomeFaq/>
    <HomeCta/>
    <!-- <HomeTestimony/> -->
    <HomeContact/>
  </div>
</template>

<script>
import HomeContact from '@/components/sections/HomeContact.vue'
import HomeCta from '@/components/sections/HomeCta.vue'
import HomeFaq from '@/components/sections/HomeFaq.vue'
import HomeHero from '@/components/sections/HomeHero.vue'
import HomeInfo from '@/components/sections/HomeInfo.vue'
import HomePerks from '@/components/sections/HomePerks.vue'
import HomePricing from '@/components/sections/HomePricing.vue'
import HomeSteps from '@/components/sections/HomeSteps.vue'
import HomeTestimony from '@/components/sections/HomeTestimony.vue'

export default {
  name: 'HomeView',
  components: {
    HomeContact,
    HomeCta,
    HomeFaq,
    HomeHero,
    HomeInfo,
    HomePerks,
    HomePricing,
    HomeSteps,
    HomeTestimony
  }
}
</script>
