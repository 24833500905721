<template>
  <section class="home-steps" id="steps">
    <div class="home-steps-cnt">
      <div class="header-slide">
        <h2 class="headline-2 fbold">5 étapes pour recruter le tech idéal</h2>
        <div class="body-md">Avec Onlytechs, vous pouvez être sûr de trouver les talents techniques d’exception dont vous avez besoin pour renforcer votre équipe et stimuler la croissance de votre entreprise.</div>
        <CtaButton label="Recruter un tech" white @click="onClickHelpMeRecruit"/>
      </div>
      <div class="step-slides">
        <Carousel
          :navigationEnabled="true"
          :scroll-per-page="false"
          :per-page="1"
          navigation-next-label=""
          navigation-prev-label=""
          pagination-color="#EAECF0"
          pagination-active-color="#101828"
        >
          <Slide v-for="(step, index) in steps" :key="step.title">
            <div class="step-slide">
              <div class="step-number headline-1 fblack">0{{ index + 1 }}</div>
              <h3 class="headline-4 fbold">{{ step.title }}</h3>
              <div class="body-md">{{ step.description }}</div>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import CtaButton from "front-lib-os/src/components/atoms/CtaButton.vue";

export default {
  name: "HomeSteps",
  components: {
    Carousel,
    Slide,
    CtaButton,
  },
  data() {
    return {
      steps: [
        {
          title: "Call de Brief du Recruteur",
          description:
            "Tout commence par un appel de brief approfondi avec le recruteur. Au cours de cette conversation, nous comprenons en détail les besoins spécifiques du recruteur, y compris les compétences requises, l'expérience souhaitée, la culture d'entreprise, et plus encore.",
        },
        {
          title: "Recherche de Candidats Qualifiés",
          description: "Après avoir recueilli les informations essentielles, notre consultant en recrutement spécialisé entame une recherche ciblée. Cette recherche inclut l'évaluation minutieuse de notre base de données de candidats hyper-spécialisés et l'entretien téléphonique de candidats potentiels. Dans un délai de 15 jours, nous présentons au recruteur une liste de candidats pré-qualifiés et correspondant parfaitement à ses critères.",
        },
        {
          title: "Organisation des Entretiens",
          description: "L'équipe Onlytechs prend ensuite en charge l'organisation pratique des entretiens. Nous coordonnons les plannings des candidats et du recruteur pour garantir une expérience d'entretien fluide et sans tracas.",
        },
        {
          title: "Short List du Recruteur",
          description: "Le recruteur a le pouvoir de décider. Après avoir examiné la liste des candidats, le recruteur établit sa short list des candidats qu'il souhaite rencontrer en personne. Cette étape permet au recruteur de conserver un contrôle total sur le processus de sélection.",
        },
        {
          title: "Lancement du Processus d'Embauche",
          description: "Si le recruteur trouve un candidat qui correspond parfaitement à ses besoins, le processus d'embauche est lancé. Nous guidons le recruteur à travers toutes les étapes, de la négociation des offres à l'intégration du nouveau talent au sein de l'entreprise.",
        }
      ]
    };
  },
  methods: {
    onClickHelpMeRecruit() {
      window.open("https://calendly.com/onlytechs/30min");
    },
  },
};
</script>

<style lang="scss">
.home-steps {
  background: $white;
  padding: 96px 0;
  color: $neutral900;

  .home-steps-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .header-slide {
      float: left;

      width: 592px;

      .headline-2 {
        margin: 0 0 16px;
      }

      .body-md {
        color: $neutral600;
        margin: 0 0 40px;
      }
    }

    .step-slides {
      float: right;
      width: 602px;
    
      .step-slide {
        width: 384px;
        height: 504px;
        padding: 32px;

        border-radius: 16px;
        border-top: 4px solid $primaryOt300;
        background: $primaryOt25;
        margin: 0 auto;

        .headline-4 {
          margin: 40px 0 16px;
        }

        .body-md {
          color: $neutral600;
        }
      }
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }

    .VueCarousel-dot:focus,
    .VueCarousel-navigation-next:focus,
    .VueCarousel-navigation-prev:focus {
      outline: none!important;
    }
    .VueCarousel-navigation-next {
      top: auto;
      bottom: -54px;
      right: 200px;

      &::after {
        content: url('front-lib-os/src/assets/right_circle.svg');
      }
    }
    .VueCarousel-navigation-prev {
      top: auto;
      bottom: -54px;
      left: 200px;

      &::after {
        content: url('front-lib-os/src/assets/left_circle.svg');
      }
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-steps {
    padding: 64px 0;

    .home-steps-cnt {
      width: $mobileContainer;

      .header-slide {
        width: 100%;
        margin: 0 0 40px;

        .headline-2 {
          margin: 0 0 16px;
        }

        .body-md {
          margin: 0 0 40px;
        }
      }

      .step-slides {
        width: 100%;
        margin: 0 auto;

        .step-slide {
          width: 95%;
          height: 580px;
          padding: 24px;

          border-radius: 16px;
          border-top: 4px solid $primaryOt300;
          background: $primaryOt25;
          margin: 0 auto 40px;

          .headline-4 {
            margin: 24px 0 16px;
          }

          .body-md {
            color: $neutral600;
          }
        }
      }

      .VueCarousel-pagination {
        margin-top: -50px;

        .tablist {
          margin-top: -20px;
        }
      }

      .VueCarousel-navigation-next {
        right: 25%;
        left: auto;
      }

      .VueCarousel-navigation-prev {
        left: 25%;
        right: auto;
      }
    }
  }
}
</style>