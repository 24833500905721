<template>
  <div id="app">
    <AppHeader :logo="logo" :whiteLogo="logoWhite">
      <template v-slot:desktop-cta>
        <CtaButton ot label="Prendre rendez-vous" size="small" :icon="extLinkIcon" @click="onClickHelpMeRecruit"/>
      </template>
      <template v-slot:mobile-cta>
        <CtaButton style="margin-top: 20px" dark label="Prendre rendez-vous" size="small" :icon="extLinkIcon" @click="onClickHelpMeRecruit"/>
      </template>
    </AppHeader>
    <router-view/>
    <AppFooter ot/>
  </div>
</template>

<script>
import AppHeader from 'front-lib-os/src/components/AppHeader.vue'
import AppFooter from 'front-lib-os/src/components/AppFooter.vue'
import CtaButton from 'front-lib-os/src/components/atoms/CtaButton.vue'

import logo from './assets/logo.svg'
import logoWhite from './assets/logo-white.svg'
import ExtLinkIcon from 'front-lib-os/src/assets/ext-link-white.svg'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    CtaButton
  },
  data () {
    return {
      logo,
      logoWhite,
      extLinkIcon: ExtLinkIcon
    }
  },
  methods: {
    onClickHelpMeRecruit() {
      window.open("https://calendly.com/onlytechs/30min");
    },
  }
}
</script>

<style lang="scss">
body {
  color: $neutral900;
}
</style>
