<template>
  <section class="home-contact" id="contact">
    <div class="home-contact-cnt">
      <div class="home-contact-left">
        <div class="img" />
      </div>

      <div class="home-contact-right">
        <h2 class="headline-2 fbold">Contactez-nous</h2>
        <div class="body-lg">Pour parler de vos recrutements.</div>

        <form onsubmit="event.preventDefault()">
          <div class="input-with-label half">
            <label class="body-sm fbold" for="name">Nom</label>
            <input class="input" type="text" id="name" name="name" placeholder="Doe" v-model="lastname" />
          </div>

          <div class="input-with-label half">
            <label class="body-sm fbold" for="firstname">Prénom</label>
            <input class="input" type="text" id="firstname" name="firstname" placeholder="John" v-model="firstname" />
          </div>

          <div class="input-with-label">
            <label class="body-sm fbold" for="email">Email</label>
            <input class="input" type="email" id="email" name="email" placeholder="john.doe@gmail.com" v-model="email" />
          </div>

          <div class="input-with-label">
            <label class="body-sm fbold" for="phone">Téléphone</label>
            <input class="input" type="phone" id="phone" name="phone" placeholder="0612345678" v-model="mobilephone" />
          </div>

          <div class="input-with-label">
            <label class="body-sm fbold" for="company"
              >Nom de l'entreprise</label
            >
            <input class="input" type="text" id="company" name="company" placeholder="Onlytechs" v-model="company" />
          </div>

          <div class="checkbox">
            <input type="checkbox" id="checkbox" name="checkbox" v-model="checkbox" />
            <label class="body-sm" for="checkbox">
              Vous acceptez notre
              <router-link to="/privacy" class="fbold">politique de confidentialité</router-link>
              .
            </label>
          </div>

          <div v-if="isError" class="body-sm fbold" style="color: red">Une erreur est survenue, veuillez vérifier vos informations et réessayer.</div>

          <CtaButton ot :label="!isSent ? 'Envoyer' : 'Votre message a bien été envoyé'" block :disabled="!canSend || isSent" @click="send" />
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import CtaButton from "front-lib-os/src/components/atoms/CtaButton.vue"
import {sendForm} from '../../helpers/hubspotHelper'

export default {
  name: "HomeContact",
  components: {
    CtaButton,
  },
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      mobilephone: "",
      company: "",
      checkbox: false,

      isSent: false,
      isError: false,
    };
  },
  computed: {
    canSend() {
      return this.lastname && this.firstname && this.email && this.mobilephone && this.company && this.checkbox;
    },
  },
  methods: {
    async send() {
      this.isError = false
      if(this.isSent) return

      this.isSent = true
      try {
        const res = await sendForm({
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          mobilephone: this.mobilephone,
          company: this.company,
        })
        console.log(res)
      } catch (error) {
        console.error({error})
        this.isError = true
        this.isSent = false
      }
    }
  }
};
</script>

<style lang="scss">
.home-contact {
  background: $white;
  padding: 96px 0;
  text-align: center;

  .home-contact-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .home-contact-left {
      width: 592px;
      float: left;

      .img {
        width: 592px;
        height: 614px;
        border-radius: 24px;
        background: url("@/assets/contact-pic.jpeg") no-repeat center;
        background-size: cover;
      }
    }

    .home-contact-right {
      width: 592px;
      float: right;
      padding: 40px;
      text-align: left;

      .headline-2 {
        margin: 0 0 16px;
      }

      form {
        margin-top: 48px;

        .input-with-label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 6px;
          align-self: stretch;
          width: 512px;
          margin-bottom: 24px;

          &.half {
            width: 248px;
            float: left;

            &:first-child {
              margin-right: 16px;
            }
          }

          input {
            width: 100%;
            padding: 10px 14px;
            border-radius: 8px;
            border: 1px solid $neutral300;
            background: $white;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }

        .checkbox {
          margin-bottom: 32px;

          input {
            border-radius: 6px;
            border: 1px solid $neutral300;
            background: $white;
          }

          label {
            padding-left: 4px;
          }
        }
      }
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-contact {
    padding: 4px 24px 64px;

    .home-contact-cnt {
      width: 100%;

      .home-contact-left {
        display: none;
      }

      .home-contact-right {
        width: 100%;
        float: none;
        padding: 0;
        margin-top: 0;

        .headline-2 {
          margin: 0 0 16px;
        }

        form {
          margin-top: 32px;

          .input-with-label {
            width: 100%;
            margin-bottom: 16px;

            &.half {
              width: 100%;
              float: none;

              &:first-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

</style>