var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"home-info"},[_c('div',{staticClass:"home-info-cnt"},[_c('div',{staticClass:"left-block"},[_c('h2',{staticClass:"headline-2 fbold"},[_vm._v("Le constat")]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-item-icon"},[_c('img',{attrs:{"src":_vm.users,"alt":"users"}})]),_vm._m(0)]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-item-icon"},[_c('img',{attrs:{"src":_vm.pieChart,"alt":"pie chart"}})]),_vm._m(1)]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-item-icon"},[_c('img',{attrs:{"src":_vm.coins,"alt":"coins"}})]),_vm._m(2)]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-item-icon"},[_c('img',{attrs:{"src":_vm.snooze,"alt":"snooze"}})]),_vm._m(3)])]),_c('div',{staticClass:"right-block"},[_c('h2',{staticClass:"headline-2 fbold"},[_vm._v("La solution")]),_vm._m(4),_vm._m(5),_vm._m(6),_c('CtaButton',{attrs:{"label":"Découvrez les étapes du recrutement","white":""},on:{"click":_vm.onClickSteps}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-item-cnt"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v(" Les cabinets de recrutement sont rarement spécialisés dans les profils techniques. ")]),_c('div',{staticClass:"body-md"},[_vm._v(" Il existe de nombreux cabinets sur le marché mais peu offrent un niveau d'expertise technique élevé et une spécialisation intégrale. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-item-cnt"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v(" Les cabinets de recrutement exigent souvent des frais fixes avant de travailler. ")]),_c('div',{staticClass:"body-md"},[_vm._v(" La plupart des cabinets de recrutement ont tendance à demander des frais fixes avant de commencer le processus de recherche de candidats. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-item-cnt"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v(" Les cabinets de recrutement proposent souvent des prix élevés. ")]),_c('div',{staticClass:"body-md"},[_vm._v(" La grande majorité des cabinets ont souvent des tarifs élevés, avec des taux de commission qui se situent généralement entre 20 et 30%. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-item-cnt"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v(" Les processus de recrutement sont souvent longs et dispersés. ")]),_c('div',{staticClass:"body-md"},[_vm._v(" Une fois le contrat de collaboration signé, il est fréquent de devoir attendre des semaines avant de recevoir des candidatures, et des mois avant de finaliser un recrutement. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-item"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v(" Un cabinet spécialisé dans les Tech ")]),_c('div',{staticClass:"body-md"},[_vm._v(" Onlytechs est le cabinet de recrutement des tech qui propose un écosystème de service à destination des recruteurs comme des candidats pour leur faciliter le quotidien. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-item"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v("100% au succès")]),_c('div',{staticClass:"body-sm fsemibold"},[_vm._v(" (sans frais fixes avec des taux de commission compétitifs) ")]),_c('div',{staticClass:"body-md"},[_vm._v(" Onlytechs propose une approche du recrutement de tech sans risque à des tarifs bas, à partir de 16% du salaire annuel brut. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-item"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v("Process ultra rapide de 3 semaines")]),_c('div',{staticClass:"body-sm fsemibold"},[_vm._v(" (entre le début de la mission et l'embauche) ")]),_c('div',{staticClass:"body-md"},[_vm._v(" Onlytechs délivre une méthodologie précise comprenant plusieurs grandes étapes sur 3 semaines pour recruter sans tarder. (edited) ")])])
}]

export { render, staticRenderFns }